import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/app/RootHead/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/shared/AnalyticsGa/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/pages/index.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/styles/exports.module.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"shared/fonts/gtAmerica/extended.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./GT-America-Extended-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./GT-America-Extended-Black.woff2\",\"style\":\"normal\",\"weight\":\"900\"}],\"variable\":\"--gt-america-extended\"}],\"variableName\":\"gtAmericaExtended\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"shared/fonts/gtAmerica/normal.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./GT-America-Standard-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./GT-America-Standard-Medium.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./GT-America-Standard-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"}],\"variable\":\"--gt-america\"}],\"variableName\":\"gtAmerica\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"shared/fonts/rivalsNumbers/logo.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./RivalsNumbers-Logo.woff2\",\"style\":\"normal\",\"weight\":\"normal\"}],\"variable\":\"--rivals-numbers-logo\"}],\"variableName\":\"rivalsNumbersLogo\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"shared/fonts/rivalsNumbers/ranking.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./RivalsNumbers-Ranking.woff2\",\"style\":\"normal\",\"weight\":\"normal\"}],\"variable\":\"--rivals-numbers-ranking\"}],\"variableName\":\"rivalsNumbersRanking\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"shared/fonts/yahooSans/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./YahooSans-Black.woff2\",\"style\":\"normal\",\"weight\":\"900\"},{\"path\":\"./YahooSans-BlackItalic.woff2\",\"style\":\"italic\",\"weight\":\"900\"},{\"path\":\"./YahooSans-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./YahooSans-BoldItalic.woff2\",\"style\":\"italic\",\"weight\":\"700\"},{\"path\":\"./YahooSans-ExtraBold.woff2\",\"style\":\"normal\",\"weight\":\"900\"},{\"path\":\"./YahooSans-ExtraBoldItalic.woff2\",\"style\":\"italic\",\"weight\":\"900\"},{\"path\":\"./YahooSans-ExtraLight.woff2\",\"style\":\"normal\",\"weight\":\"200\"},{\"path\":\"./YahooSans-Italic.woff2\",\"style\":\"italic\",\"weight\":\"400\"},{\"path\":\"./YahooSans-Light.woff2\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"./YahooSans-LightItalic.woff2\",\"style\":\"italic\",\"weight\":\"300\"},{\"path\":\"./YahooSans-Medium.woff2\",\"style\":\"normal\",\"weight\":\"500\"},{\"path\":\"./YahooSans-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./YahooSans-Semibold.woff2\",\"style\":\"normal\",\"weight\":\"600\"}],\"variable\":\"--yahoo-sans\"}],\"variableName\":\"yahooSans\"}");
